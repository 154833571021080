import React from "react";
import BgImg from "gatsby-background-image";
// import { RichText } from "prismic-reactjs";
import Div100vh from "react-div-100vh";

const Figure = ({ size, input }) => (
  <figure className="h100">
    {/* <BgImg
      fluid={input.image.fluid}
      alt={input.image.alt}
      className="h100"
      style={{
        backgroundSize: size,
        transition: "none",
      }}
      imgStyle={{
        transition: "none",
      }}
      // loading="eager"
      // fadeIn={false}
    /> */}
    <div
      className="h100"
      style={{
        backgroundImage: `url(${input.image.url})`,
        backgroundSize: size,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        maxHeight: "calc(var(--vh, 1vh)*100)",
      }}
    ></div>
  </figure>
);
const SlideImage = ({ size, input }) => (
  <div className="slide">
    {size === "cover" ? (
      <Div100vh>
        <Figure size={size} input={input} />
      </Div100vh>
    ) : (
      <Figure size={size} input={input} />
    )}
  </div>
);

export default SlideImage;
