import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { linkResolver } from "../../core/utils";
import ProjetCard from "./ProjetCard";
import ProjetsTable from "./ProjetsTable";
// import SEO from "../seo/SEO";
import clsx from "clsx";

const query = graphql`
  query {
    allPrismicProjet(limit: 2) {
      nodes {
        type
        uid
        data {
          ...projet
        }
      }
    }
  }
`;

const Projets = ({ defaultDisplayType = "all", input }) => {
  const [displayType, setDisplayType] = useState(defaultDisplayType);

  const { allPrismicProjet } = useStaticQuery(query);
  // console.log(input);
  // console.log(allPrismicProjet);
  useEffect(() => {
    const token = PubSub.subscribe("DISPLAY_TYPE", (e, d) => {
      // console.log(e, d);
      setDisplayType(d);
    });

    return () => PubSub.unsubscribe(token);
  }, []);
  // console.log(allPrismicProjet);
  /**
   * filter project that have R as status
   */
  // const rProjet = allPrismicProjet.nodes.filter((el) => el.data.sta === "R");
  // console.log(rProjet);
  // const columns = [
  //   "sta",
  //   "cat",
  //   "abc",
  //   // "br",
  //   "projet",
  //   "lieu",
  //   "phase",
  //   "date",
  // ];
  return (
    <div className={clsx("projets fSeg fM", `display-${displayType}`)}>
      {displayType === "all" && (
        <>
          <div className="grid mb-m">
            <div className="row">
              {input.map((el, i) => (
                <ProjetCard key={i} input={el.item?.document} />
              ))}
            </div>
          </div>
          <div className="liste">
            <ProjetsTable input={input} />
          </div>
        </>
      )}
      {displayType === "grid" && (
        <div className="grid mb-m">
          <div className="row">
            {input.map((el, i) => (
              <ProjetCard key={i} input={el.item?.document} />
            ))}
          </div>
        </div>
      )}

      {displayType === "liste" && (
        <div className="liste">
          <ProjetsTable input={input} />
        </div>
      )}
    </div>
  );
};

export default Projets;
