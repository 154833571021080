import React, { useState } from "react";
import { Link } from "gatsby";
import { linkResolver } from "../../core/utils";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  .row {
    margin: 0;
  }
  .body .row:hover {
    background-color: black;
    &,
    a {
      color: white;
    }
  }
  .col-xs {
    padding: 4px 0;

    &:not(.col-date) {
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: black;
    }
  }
  .col-sta,
  .col-cat {
    /* min-width: 2.355em;
    padding-right: calc(var(--gutter) / 4); */
  }
  .col-sta,
  .col-cat,
  .col-abc {
    flex-grow: 0;
    white-space: pre;
  }
  .col-abc {
    min-width: calc(1.9383333333333335em + var(--gutter));
    padding-right: var(--gutter) !important;
  }
  --metasWidth: calc(
    50% - ((2.355em * 2) + calc(1.9383333333333335em)) - var(--gutter) / 2
  );

  .col-projet {
    @media all and (min-width: 1024px) {
      max-width: var(--metasWidth);
      flex-basis: var(--metasWidth);
      padding-right: calc(var(--gutter) / 1);
    }
  }
  .col-lieu {
    /* 1304 > 100
    331 */
    max-width: 25.38343558%;
    flex-basis: 25.38343558%;
  }
  .col-phase {
    /* max-width: calc(100% / 12 * 2);
    flex-basis: calc(100% / 12 * 2); */
  }
  .col-date {
    text-align: right;
    max-width: 7.1%;
    flex-basis: 7.1%;
    flex-grow: 0;
  }
`;

const ProjetsTable = ({ input }) => {
  // console.log(input);
  const [data, setData] = useState();
  const [sorted, setSorted] = useState(false);
  const [order, setOrder] = useState("ASC");

  const columns = [
    "sta",
    "cat",
    "abc",
    // "br",
    "projet",
    "lieu",
    "phase",
    "date",
  ];

  const _sortBy = (key) => {
    let arrayCopy = [...input];
    arrayCopy.sort(_compareBy(key));
    // console.table(arrayCopy[0].data.title.text);
    setData(arrayCopy);
    setSorted(true);
    setOrder(order === "ASC" ? "DESC" : "ASC");
  };

  const _compareBy = (key) => {
    // console.log(order, key);
    if (order == "ASC") {
      return function (a, b) {
        // console.log(a.item?.document.data[key], b.item?.document.data[key]);
        if (typeof a.item?.document.data[key] === "object") {
          if (
            a.item?.document.data[key].text.toLowerCase() <
            b.item?.document.data[key].text.toLowerCase()
          )
            return -1;
          if (
            a.item?.document.data[key].text.toLowerCase() >
            b.item?.document.data[key].text.toLowerCase()
          )
            return 1;
          return 0;
        } else {
          /**
           * prevent null values
           */
          const _a = a.item?.document.data[key]
            ? a.item?.document.data[key]
            : "";
          const _b = b.item?.document.data[key]
            ? b.item?.document.data[key]
            : "";
          if (_a.toLowerCase() < _b.toLowerCase()) return -1;
          if (_a.toLowerCase() > _b.toLowerCase()) return 1;
          return 0;
        }
      };
    } else {
      return function (a, b) {
        if (typeof a.item?.document.data[key] === "object") {
          if (
            a.item?.document.data[key].text.toLowerCase() <
            b.item?.document.data[key].text.toLowerCase()
          )
            return 1;
          if (
            a.item?.document.data[key].text.toLowerCase() >
            b.item?.document.data[key].text.toLowerCase()
          )
            return -1;
          return 0;
        } else {
          const _a = a.item?.document.data[key]
            ? a.item?.document.data[key]
            : "";
          const _b = b.item?.document.data[key]
            ? b.item?.document.data[key]
            : "";
          if (_a.toLowerCase() < _b.toLowerCase()) return 1;
          if (_a.toLowerCase() > _b.toLowerCase()) return -1;
          return 0;
        }
      };
    }
  };

  const projets = sorted ? data : input;

  return (
    <Container className=" fM ttu">
      <div className="head">
        <div className="row">
          {columns.map((column, i) => (
            <div
              key={i}
              className={`col-xs col-${column} ttu curp`}
              onClick={() => _sortBy(column === "projet" ? "title" : column)}
            >
              <div>
                <span>{column}</span>
                <span> </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="body">
        {projets.map((el, i) => (
          <div className="row" key={i}>
            {columns.map((column, j) => (
              <div key={j} className={`col-xs col-${column}`}>
                <div>
                  <Link to={linkResolver(el.item?.document)}>
                    {column === "projet" ? (
                      <span>{el.item?.document.data.title.text}</span>
                    ) : (
                      <span>{el.item?.document.data[column]}</span>
                      // <pre>
                      //   {JSON.stringify(el.item?.document.data[column])}
                      // </pre>
                    )}
                    {column === "sta" && <span>{"   "}</span>}
                    {column === "cat" && <span> </span>}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default ProjetsTable;
