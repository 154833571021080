import React, { useState, useEffect, useContext, useRef } from "react";
import PubSub from "pubsub-js";
import SlickSlider from "react-slick";
import SlideImage from "./SlideImage";
import { WrapperContext } from "../Layout";

const Slider = ({ size, input, autoplay, startAt = 0, onChange }) => {
  const { isMobile } = useContext(WrapperContext);

  const [current, setCurrent] = useState(1);
  const [length, setLength] = useState(input.length || 0);
  // const [isMobile, setIsMobile] = useState(undefined);

  const sliderRef = useRef();

  useEffect(() => {
    // if (window.innerWidth < 768 && "ontouchstart" in window) setIsMobile(true);
    // console.log("startAt", startAt);
    setCurrent(startAt + 1);

    document.addEventListener("keydown", _onKey);
  }, []);

  useEffect(() => {
    PubSub.publish("SLIDER_INDEX", {
      current: current,
      length: length,
    });
  }, [current]);

  const _onKey = (e) => {
    // console.log(e.keyCode)
    switch (e.keyCode) {
      case 37:
        sliderRef.current.slickPrev();
        break;
      case 39:
        sliderRef.current.slickNext();
        break;
    }
  };
  // const _onEdge = () => {};
  // const _sliderBeforeChange = (oldIndex, newIndex) => {};
  const _sliderAfterChange = (index) => {
    // setCurrent(index + 1);
    // console.log(input[index + 1]);
    PubSub.publish("SLIDER_INDEX", {
      index: index,
      current: index + 1,
      length: length,
      // legende: "la légende",
    });
    // onChange(index + 1);
  };

  const settings = {
    // fade: !isMobile,
    fade: isMobile ? false : true,
    autoplay: autoplay,
    dots: false,
    speed: isMobile ? 200 : 600,
    // speed: 400,
    // speed: 10,
    swipe: isMobile,
    initialSlide: startAt,
    // lazyLoad: true,
    // beforeChange: _sliderBeforeChange,
    afterChange: _sliderAfterChange,
    // onEdge: _onEdge,
    cssEase: "cubic-bezier(0.53, 0, 0.36, 1)",
  };

  return (
    <div className={"slider h100"}>
      <SlickSlider ref={sliderRef} {...settings}>
        {input.map((el, i) => (
          <div key={i}>
            <SlideImage size={size} input={el} i={i} />
          </div>
        ))}
      </SlickSlider>
      <div className="slider-pagination">{`${current}/${length}`}</div>
    </div>
  );
};

export default Slider;
