import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import LinkFade from "../ui/TransitionLinks/LinkFade";
import BgImg from "gatsby-background-image";
import Img from "gatsby-image";
import { linkResolver } from "../../core/utils";

const ProjetCard = ({ input }) => {
  // console.log(input);
  // const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // if (window.innerWidth < 1024 && "ontouchstart" in window) setIsMobile(true);
  }, []);

  const { image_clef, sta, cat, abc } = input.data;
  // console.log(image_clef);
  return (
    <div className="card projet-card col-md-3 col-xs-12">
      <LinkFade to={linkResolver(input)}>
        <div className="outter a-r-a4">
          <div className="inner x xdc xje">
            {image_clef && image_clef.fluid && <Img {...image_clef} />}
          </div>
        </div>

        <ul className="card-body x ttu fSeg">
          <li>
            <div className="sta">{sta}</div>
          </li>
          <li>{"   "}</li>
          <li>
            <div className="cat">{cat}</div>
          </li>
          <li> </li>
          <li>
            <h2>{abc}</h2>
          </li>
        </ul>
      </LinkFade>
    </div>
  );
};

export default ProjetCard;
