import React from "react";
import { RichText, Elements } from "prismic-reactjs";

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url);
  return decoded.substring(decoded.lastIndexOf("/") + 1);
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export const linkResolver = (doc) => {
  if (doc.data.home_page) {
    return "/";
  }
  switch (doc.type) {
    case "projet":
      return `/projet/${doc.uid}`;

    default:
      return `/${doc.uid}`;
  }
};

// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};
const isNumeric = function (n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

// -- HTML Serializer
export const htmlSerializer = function (type, element, content, children, key) {
  var props = {};

  switch (type) {
    case Elements.hyperlink:
      const targetAttr = element.data.target
        ? { target: element.data.target }
        : {};
      const relAttr = element.data.target ? { rel: "noopener" } : {};
      props = Object.assign(
        {
          className: "link-class",
          href: element.data.url || linkResolver(element.data),
        },
        targetAttr,
        relAttr
      );
      return React.createElement("a", propsWithUniqueKey(props, key), children);

    case Elements.listItem: // Unordered List Item
      // console.log(element.text);
      //First letter is a number > tabulation css
      // const firstWord = element.text.split(" ")[0];
      // const firstLetter = firstWord.split("")[0];
      // const leftOver = element.text.replace(firstWord, "");
      // if (isNumeric(firstLetter)) {
      //   // console.log(firstWord, "is num");
      //   const liHtml = React.createElement("div", {
      //     dangerouslySetInnerHTML: {
      //       __html: `<div class="x"><div class="date">${firstWord}</div><div class="leftOver">${leftOver}</div></div>`,
      //     },
      //   });
      //   return React.createElement(
      //     "li",
      //     propsWithUniqueKey(props, key),
      //     liHtml
      //   );
      // } else {
      //   return React.createElement(
      //     "li",
      //     propsWithUniqueKey(props, key),
      //     children
      //   );
      // }
      const hasDate = element.text.indexOf("[separateur]") > -1;
      if (hasDate) {
        const parts = element.text.split("[separateur]");
        const liHtml = React.createElement("div", {
          dangerouslySetInnerHTML: {
            __html: `<div class="x list-key-val"><div class="key">${parts[0]}</div><div class="val">${parts[1]}</div></div>`,
          },
        });
        return React.createElement(
          "li",
          propsWithUniqueKey(props, key),
          liHtml
        );
      } else {
        return React.createElement(
          "li",
          propsWithUniqueKey(props, key),
          children
        );
      }

    default:
      // Always include a default that returns null
      return null;
  }
};
