import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";

const SliderPagination = ({ total, TOPIC = "" }) => {
  const [current, setCurrent] = useState(1);
  // const [length, setLength] = useState(length || 0);

  useEffect(() => {
    let evt = "SLIDER_INDEX";
    if (TOPIC !== "") evt = TOPIC;
    console.log(evt);
    const token = PubSub.subscribe(evt, (e, d) => {
      // console.log(d);
      // if (d.sliderID !== sliderID) return;
      setCurrent(d.current);
    });

    return () => PubSub.unsubscribe(token);
  }, []);

  return <div className="slider-pagination fSeg">{`${current}/${total}`}</div>;
};

export default SliderPagination;
